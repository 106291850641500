import React, { useState, useEffect, useRef } from 'react';
import { Flex, Input, Button, Stack, InputGroup, InputRightElement, IconButton, Box } from '@chakra-ui/react';
import { RangeDatepicker } from "chakra-dayzed-datepicker";
import { FaSearch } from 'react-icons/fa';
import { CloseIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';
import { getBoundsOfDistance } from 'geolib';

const libraries = ['places', 'geometry']; // Specify the libraries you need

const MapSearchBar = ({ handleSearch, setBounds, setCenter, selectedDates, setSelectedDates }) => {
  const today = new Date();
  const oneYearLater = moment(today).add(1, 'year').toDate();

  const [query, setQuery] = useState('Cancun, Quintana Roo, Mexico');
  const [inputValue, setInputValue] = useState('Cancun, Quintana Roo, Mexico');
  const [results, setResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);

  const [selectedPlace, setSelectedPlace] = useState({ 'label': 'Cancun, Quintana Roo, Mexico', 'y': 21.161908, 'x': -86.8515279, 'bounds': [[21.161908, -86.8515279], [21.161908, -86.8515279]], formatted_address: 'Cancun, Quintana Roo, Mexico' });
  const checkinDate = selectedDates[0];
  const checkoutDate = selectedDates[1];

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyAXxMWxVuqQvCJFwsMwjTFDgrXzP-sTyHQ",
    libraries
  })

  console.log('google map isLoaded:', isLoaded);


  function onLoad(autocomplete) {
    setPlaceAutocomplete(autocomplete);
  }

  function onPlaceChanged() {
    if (placeAutocomplete != null) {
      setInputValue(placeAutocomplete.getPlace().formatted_address);
      const place = placeAutocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        return;
      }

      // const bounds = `${place.geometry.viewport.getSouthWest().lat()},${place.geometry.viewport.getNorthEast().lat()},${place.geometry.viewport.getSouthWest().lng()},${place.geometry.viewport.getNorthEast().lng()}`;
      const center = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
      
      const boundsOfDistance = getBoundsOfDistance(center, 50000); //bounds of 50km
      const bounds = [boundsOfDistance[0].latitude, boundsOfDistance[1].latitude, boundsOfDistance[0].longitude, boundsOfDistance[1].longitude];
      console.log('boundsOfDistance:', boundsOfDistance);

      setBounds(bounds);
      setCenter(center);
      setSelectedPlace(place);
    } else {
      alert('Please enter text');
    }
  }

  // const provider = new OpenStreetMapProvider();

  // // Debounce the search operation, not the setQuery
  // const debouncedSearch = debounce((searchQuery) => {
  //   // Perform the search operation here
  //   console.log('Searching for:', searchQuery);
  //   provider
  //     .search({ query })
  //     .then((results) => {
  //       console.log('Results:', results);
  //       if (results && results.length > 0) {
  //         // filter results to only include .raw.addresstype = 'city' or 'state'
  //         results = results.filter(result => result.raw.addresstype === 'city' || result.raw.addresstype === 'state' || result.raw.addresstype === 'province');
  //         console.log('Filtered Results:', results);
  //         setResults(results);
  //         // setResults(results.slice(0, 5))
  //         // setBounds(results[0].bounds);
  //         // setCenter({
  //         //   lat: results[0].y,
  //         //   lng: results[0].x
  //         // });
  //         // setSelectedPlace(results[0]);
  //       }
  //     });
  // }, 500); // Adjust the delay as needed

  // useEffect(() => {
  //   if (query) {
  //     debouncedSearch(query);
  //   }
  //   // Cleanup the debounce function on component unmount
  //   return () => debouncedSearch.cancel();
  // }, [query]);


  return (
    <Stack direction='column' gap={3} maxW='6xl' width='100%' mt={5} mb={2} zIndex="1000">
      <Flex direction={{ base: 'column', md: 'row' }} justify='center' gap='2'>
        <Stack direction='column' gap={0} flexGrow='1' position='relative' h='100%' w='100%'>
            {isLoaded ?
              <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                <Input
                  placeholder='Location'
                  size='md'
                  value={inputValue}
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSearch(checkinDate, checkoutDate, selectedPlace);
                    }
                  }}
                  onChange={(e) => setInputValue(e.target.value)}
                  width={{ base: '100%', md: '100%' }}
                  flexGrow='1'
                  zIndex={1}
                />
              </Autocomplete> : <></>
            }
            {inputValue && (
                <IconButton
                  aria-label="Clear input"
                  icon={<CloseIcon />}
                  size="sm"
                  position='absolute'
                  top='20px'
                  transform='translateY(-50%)'
                  right={1}
                  zIndex={2}
                  onClick={() => {
                    setInputValue('')
                    setSelectedPlace({});
                  }
                  }
                  variant="ghost"
                />
            )}
        </Stack>
        <Stack direction='row' gap={1} flexGrow='0' >
          <RangeDatepicker
            selectedDates={selectedDates}
            onDateChange={setSelectedDates}
            startDatePlaceholder="Check-in"
            endDatePlaceholder="Check-out"
            minDate={today}
            maxDate={oneYearLater}
          />
          <Button flexShrink={0} onClick={() => handleSearch(checkinDate, checkoutDate, selectedPlace)}><FaSearch /></Button>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default MapSearchBar;