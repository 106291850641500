import React, { useState } from 'react';
import { Flex, RangeSlider, RangeSliderMark, RangeSliderFilledTrack, RangeSliderTrack, RangeSliderThumb, Stack, Text, 
  Box, InputRightElement, MenuItemOption, MenuOptionGroup, MenuItem, MenuGroup, IconButton, RadioGroup, Radio,
  InputGroup, InputLeftAddon, Input } from '@chakra-ui/react';
import { FaMoneyBill } from "react-icons/fa";
import { FaStar } from "react-icons/fa";
import { TbTriangleSquareCircleFilled, TbSortAscending } from "react-icons/tb";
import { CloseIcon } from '@chakra-ui/icons';

import SearchMenuButton from './SearchMenuButton';
import { set } from 'lodash';

const SearchFilterBar = ({
  resultCount,
  brands,
  brandCount,
  setBrands,
  subBrandOptions,
  subBrandCount,
  subBrands,
  setSubBrands,
  maxPrice,
  setPriceRange,
  maxPoints,
  setPointsRange,
  priceRangeSlider,
  setPriceRangeSlider,
  pointsRangeSlider,
  setPointsRangeSlider,
  nameFilter,
  setNameFilter,
  sortBy,
  setSortBy,
  brandsLoaded
}) => {
  const brandOptions = ['Hilton', 'Hyatt', 'IHG', 'Marriott'];

  const subBrandOnly = (e, brand, subBrand) => {
    console.log('only sub brand:', brand, subBrand)
    setSubBrands({ [brand]: [subBrand] });
    e.stopPropagation();
  }

  const brandOnly = (e, brand) => {
    console.log('only brand', brand)
    setBrands([brand]);
    e.stopPropagation();
  }

  const resetPriceFilter = () => {
    setPriceRange([0, maxPrice]);
    setPriceRangeSlider([0, 100]);
  }

  const resetPointsFilter = () => {
    setPointsRange([0, maxPoints]);
    setPointsRangeSlider([0, 100]);
  }

  const resetSortBy = () => {
    setSortBy('Popularity');
    setSortTitle('Sort By');
  }

  const [sortTitle, setSortTitle] = useState('Sort By');
  const [sortIcon, setSortIcon] = useState(<TbSortAscending/>);


  return (
    <Flex direction='column' justify='space-between' px={1} zIndex="500">
      <Flex direction='row' justify='space-between' gap={1}>
        <Flex overflow="auto" maxW="100%">
          <Stack direction='row' gap={2} overflow="auto" maxW='100%'>
            <InputGroup size='sm' minW='250px'>
              <InputLeftAddon>Name Filter</InputLeftAddon>
              <Input type='text' placeholder='' value={nameFilter} onChange={(e) => setNameFilter(e.target.value)}/>
              {nameFilter && (
              <InputRightElement>
                <IconButton
                  aria-label="Clear input"
                  icon={<CloseIcon />}
                  size="sm"
                  onClick={() => setNameFilter('')}
                  variant="ghost"
                />
              </InputRightElement>
            )}
            </InputGroup>
            <SearchMenuButton title={sortTitle} icon={sortIcon} 
              reset={resetSortBy}              
            >
              <Box width='sm' px={8} py={8}>
                <RadioGroup defaultValue="Popularity" 
                  onChange={(val) => {
                    setSortBy(val);
                    setSortIcon(<TbSortAscending/>);
                    setSortTitle(val);
                  }}
                >
                  <Stack direction="column">
                    <Radio value="Popularity">Popularity</Radio>
                    <Radio value="Lowest Points">Lowest Points</Radio>
                    <Radio value="Lowest Price">Lowest Price</Radio>
                    <Radio value="Highest Value">Highest Value</Radio>
                    <Radio value="Highest Price">Highest Price</Radio>
                  </Stack>
                </RadioGroup>
              </Box>
            </SearchMenuButton>
            <SearchMenuButton title='Brand' icon={<TbTriangleSquareCircleFilled />} reset={() => setBrands(brandOptions)} variant='outline'>
              <MenuOptionGroup
                type='checkbox'
                defaultValue={brands}
                value={brands}
                onChange={(val) => setBrands(val)}
              >
                {brandOptions.map((brandOption) => (
                  <MenuItemOption key={brandOption} value={brandOption}>
                    <Flex direction='row' justify='space-between' align='center' gap={3}>
                      <Stack direction='row' gap={2} align='center'>
                        <Text>{brandOption}</Text>
                        <Text textColor='gray.400' fontWeight='medium' fontSize='sm'>({brandCount[brandOption]})</Text>
                      </Stack>
                      <Text textColor='blue.500' fontWeight='medium' mr={1} onClick={(e) => brandOnly(e, brandOption)}>only</Text>
                    </Flex>
                  </MenuItemOption>
                ))}
              </MenuOptionGroup>
            </SearchMenuButton>
            <SearchMenuButton title='Sub Brand' icon={<TbTriangleSquareCircleFilled />} reset={() => setSubBrands(subBrandOptions)}>
              {Object.keys(subBrandOptions).length != 0 ? brands.map((brand) => (
                subBrandOptions[brand] && subBrandOptions[brand].length > 0 ? (
                  <MenuOptionGroup
                    key={brand}
                    title={brand}
                    type='checkbox'
                    defaultValue={subBrandOptions[brand]}
                    value={subBrands[brand] ? subBrands[brand] : []}
                    onChange={(val) => {
                      setSubBrands({ ...subBrands, [brand]: val });
                    }}
                  >
                    {subBrandOptions[brand].map((subBrandOption) => (
                      <MenuItemOption key={subBrandOption} value={subBrandOption}>
                        <Flex direction='row' justify='space-between' align='center' gap={3}>
                          <Stack direction='row' gap={2} align='center'>
                            <Text>{subBrandOption}</Text>
                            <Text textColor='gray.400' fontWeight='medium' fontSize='sm'>({subBrandCount[brand][subBrandOption]})</Text>
                          </Stack>
                          <Text
                            textColor='blue.500'
                            fontWeight='medium'
                            mr={1}
                            onClick={(e) => subBrandOnly(e, brand, subBrandOption)}
                            _hover={{ textDecoration: 'underline' }}
                          >
                            only
                          </Text>
                        </Flex>
                      </MenuItemOption>
                    ))}
                  </MenuOptionGroup>
                ) : (
                  <MenuGroup key={brand} title={brand}>
                    <MenuItem pl={8}>None available</MenuItem>
                  </MenuGroup>
                )
              )) : (
                <MenuItem>Please search for a location first</MenuItem>
              )}
            </SearchMenuButton>
            <SearchMenuButton title='Points' icon={<FaStar />} reset={resetPointsFilter}>
              <Box width='sm' px={14} py={10}>
                <RangeSlider
                  aria-label={['min', 'max']}
                  defaultValue={[0, 100]}
                  value={pointsRangeSlider}
                  onChange={(val) => setPointsRangeSlider(val)}
                  onChangeEnd={(val) => setPointsRange([val[0] * maxPoints / 100, val[1] * maxPoints / 100])}
                >
                  <RangeSliderMark value={0} mt='2' ml='-2.5' fontSize='sm'>
                    0
                  </RangeSliderMark>
                  <RangeSliderMark value={100} mt='2' ml='-4' fontSize='sm'>
                    {maxPoints}
                  </RangeSliderMark>
                  <RangeSliderMark
                    value={pointsRangeSlider[0]}
                    textAlign='center'
                    fontSize='sm'
                    bg='blue.500'
                    color='white'
                    mt='-9'
                    ml='-5'
                    w='12'
                  >
                    {pointsRangeSlider[0] * maxPoints / 100000}K
                  </RangeSliderMark>
                  <RangeSliderMark
                    value={pointsRangeSlider[1]}
                    textAlign='center'
                    fontSize='sm'
                    bg='blue.500'
                    color='white'
                    mt='-9'
                    ml='-5'
                    w='12'
                  >
                    {pointsRangeSlider[1] * maxPoints / 100000}K
                  </RangeSliderMark>
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} />
                  <RangeSliderThumb index={1} />
                </RangeSlider>

              </Box>
            </SearchMenuButton>
            <SearchMenuButton title='Price' icon={<FaMoneyBill />} reset={resetPriceFilter}>
              <Box width='sm' px={14} py={10}>
                <RangeSlider
                  aria-label={['min', 'max']}
                  defaultValue={[0, 100]}
                  value={priceRangeSlider}
                  onChange={(val) => setPriceRangeSlider(val)}
                  onChangeEnd={(val) => setPriceRange([val[0] * maxPrice / 100, val[1] * maxPrice / 100])}
                >
                  <RangeSliderMark value={0} mt='2' ml='-2.5' fontSize='sm'>
                    $0
                  </RangeSliderMark>
                  <RangeSliderMark value={100} mt='2' ml='-4' fontSize='sm'>
                    ${maxPrice}
                  </RangeSliderMark>
                  <RangeSliderMark
                    value={priceRangeSlider[0]}
                    textAlign='center'
                    fontSize='sm'
                    bg='blue.500'
                    color='white'
                    mt='-9'
                    ml='-5'
                    w='12'
                  >
                    ${Math.round(priceRangeSlider[0] * maxPrice / 100)}
                  </RangeSliderMark>
                  <RangeSliderMark
                    value={priceRangeSlider[1]}
                    textAlign='center'
                    fontSize='sm'
                    bg='blue.500'
                    color='white'
                    mt='-9'
                    ml='-5'
                    w='12'
                  >
                    ${Math.round(priceRangeSlider[1] * maxPrice / 100)}
                  </RangeSliderMark>
                  <RangeSliderTrack>
                    <RangeSliderFilledTrack />
                  </RangeSliderTrack>
                  <RangeSliderThumb index={0} />
                  <RangeSliderThumb index={1} />
                </RangeSlider>
              </Box>
            </SearchMenuButton>
          </Stack>
        </Flex>
      </Flex>
      <Flex direction='row' justify='space-between' gap={1} marginTop={1}>
        <Text>{resultCount} Results with {brandsLoaded} Brands</Text>
      </Flex>
    </Flex>
  );
};

export default SearchFilterBar;
