import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom'
import { Box, Text, Heading, Link as ChakraLink, LinkProps } from '@chakra-ui/react';

const NavBar = () => {
  return (
    <Box bg='#f8f9fa' p={3} display="flex" alignItems="center" gap="4">
      <ChakraLink as={ReactRouterLink} to='https://maxmypoint.com' _hover={{ textDecoration: 'none' }}>
        <Heading size='md' color='#0d6efd'>MaxMyPoint</Heading>
        <Text color="gray.500" fontSize=".8rem">Maximize Hotel Point Value</Text>
      </ChakraLink>
      <ChakraLink as={ReactRouterLink} to='https://maxmypoint.com/alerts.html' _hover={{ textDecoration: 'none' }}>
        <Text color="blue.500" size="xs">Alert</Text>
      </ChakraLink>
      <ChakraLink as={ReactRouterLink} to='https://maxmypoint.com/deals.html' _hover={{ textDecoration: 'none' }}>
        <Text color="blue.500" size="xs">Deals</Text>
      </ChakraLink>
      <ChakraLink as={ReactRouterLink} to='https://maxmypoint.com/pricing.html' _hover={{ textDecoration: 'none' }}>
        <Text color="blue.500" size="xs">Pricing</Text>
      </ChakraLink>
      <ChakraLink as={ReactRouterLink} to='https://maxfhr.com' _hover={{ textDecoration: 'none' }}>
        <Text color="blue.500" size="xs">MaxFHR</Text>
      </ChakraLink>
    </Box>
  );
};

export default NavBar;
