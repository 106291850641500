import { Button, Card, CardBody, CardFooter, Flex, Image, Stack, Heading, Text, Stat, StatLabel, StatNumber, Link as ChakraLink, useBreakpointValue } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const SearchHotelCard = ({ hotel }) => {
  const [imageUrl, setImageUrl] = useState(hotel.i);

  return (
    <Card
      direction={['column', 'row']} 
      overflow='hidden'
      variant='outline'
      borderColor='gray.300'
    >
      <Image
        objectFit='cover'
        maxW={{base: '100%', md: '200px'}}
        maxH={{ base: '150px', md: '250px' }}
        src={imageUrl}
        alt={hotel.n}
      />
      <Stack width='100%'>
        <CardBody width='100%'>
          <Stack direction='column' gap={3} width='100%'>
            <Heading size='md'><ChakraLink as={RouterLink} to={`https://maxmypoint.com/hotel/${hotel.id}`} target='_blank'>
              {hotel.n}
            </ChakraLink>
            </Heading>
            <Flex direction='row'>
              <Stat width='50%'>
                <StatNumber color={hotel.pr ? '' : 'gray'}>{hotel.pr ? '$' + Math.round(hotel.pr).toLocaleString() : 'N/A'}</StatNumber>
                <StatLabel color={hotel.pr ? '' : 'gray'}>Total Price</StatLabel>
              </Stat>
              <Stat width='50%'>
                <StatNumber color={hotel.pt ? '' : 'gray'}>{hotel.pt ? (hotel.pt / 1000).toFixed(1).toLocaleString() + 'K' : 'N/A'}</StatNumber>
                <StatLabel color={hotel.pt ? '' : 'gray'}>Total Points</StatLabel>
              </Stat>
              <Stat width='50%'>
                <StatNumber color={hotel.v ? '' : 'gray'}>{hotel.v ? (hotel.v).toFixed(1).toLocaleString() : 'N/A'}</StatNumber>
                <StatLabel color={hotel.v ? '' : 'gray'}>{useBreakpointValue({ base: 'CPP', md: 'Cents Per Point' })}</StatLabel>
              </Stat>
            </Flex>
          </Stack>
        </CardBody>
        <CardFooter pt={0} gap={5}>
          <ChakraLink
            as={RouterLink}
            to={`https://maxmypoint.com/hotel/${hotel.id}`}
            target='_blank'
          >
            <Button variant='solid' colorScheme='blue'>
              View Calendar & Setup Alert
            </Button>
          </ChakraLink>
          <ChakraLink
            as={RouterLink}
            to={`https://maxmypoint.com/hotel/${hotel.id}`}
            target='_blank'
          >
            {/* <Button variant='solid' colorScheme='blue'>
              Setup Alert
            </Button> */}
          </ChakraLink>
        </CardFooter>
      </Stack>
    </Card>
  );
};

export default SearchHotelCard;
