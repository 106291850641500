import { Box, Divider, Flex, Icon, Spinner, Stack, Text, useToast, Progress } from '@chakra-ui/react';
import React, { useState, useEffect, useRef } from 'react';
import SearchHotelCard from './SearchHotelCard';
// import MapSearchBar from './MapSearchBar';
import MapSearchBar from './MapSearchBarOSM';
import SearchFilterBar from './SearchFilterBar';
// import Map from './Map';
import Map from './MapOSM';
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import moment from 'moment';

import Holidays from 'date-holidays';

import { Button, useBreakpointValue } from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';

const MapSearchPage = () => {
  const hd = new Holidays();
  hd.init('US');

  const holidayStartDate = moment().add(2, 'days').toDate();
  const holidayEndDate = moment().add(180, 'days').toDate();

  const startYear = holidayStartDate.getFullYear();
  const endYear = holidayEndDate.getFullYear();

  let holidays = [];

  // Get holidays for the current year and the next if the range spans over two years
  for (let year = startYear; year <= endYear; year++) {
    holidays = [...holidays, ...hd.getHolidays(year)];
  }

  // Filter holidays to future public holidays
  const filteredHolidays = holidays.filter(holiday => {
    const holidayDate = new Date(holiday.date);
    return holidayDate > holidayStartDate && holiday.type === 'public';
  });

  // make start date as the day before the first public holiday
  const startDate = moment(filteredHolidays[0].date).add(-1, 'days').toDate();
  const endDate = moment(startDate).add(2, 'days').toDate();
  // setSelectedDates([startDate, endDate]);

  // setCenter({ lat: 21.161908, lng: -86.8515279 });
  // setBounds([21.02976323827379,21.2130332751345,-86.97226755449996,-86.74054014055625]);
  // setCenteredLocation({ lat: 21.161908, lng: -86.8515279 });
  // console.log(center, bounds, centeredLocation)
  // handleSearch(startDate, endDate, { label: 'Cancun, Quintana Roo, Mexico' });

  const [maxPrice, setMaxPrice] = useState(4000);
  const [maxPoints, setMaxPoints] = useState(1000000);
  const brandOptions = ['Hilton', 'Hyatt', 'IHG', 'Marriott'];
  const [brandCount, setBrandCount] = useState({
    'Hilton': 0,
    'Hyatt': 0,
    'IHG': 0,
    'Marriott': 0
  });
  const [subBrandOptions, setSubBrandOptions] = useState({});
  const [subBrandCount, setSubBrandCount] = useState({});

  const [centeredLocation, setCenteredLocation] = useState({ lat: 21.161908, lng: -86.8515279 });
  const [bounds, setBounds] = useState([21.02976323827379, 21.2130332751345, -86.97226755449996, -86.74054014055625]);
  const [center, setCenter] = useState({ lat: 21.161908, lng: -86.8515279 });
  const [hotelCoords, setHotelCoords] = useState([]);

  const [allHotels, setAllHotels] = useState([]);
  const [filteredHotels, setFilteredHotels] = useState([]);

  const [priceRange, setPriceRange] = useState([0, maxPrice]);
  const [pointsRange, setPointsRange] = useState([0, maxPoints]);
  const [priceRangeSlider, setPriceRangeSlider] = useState([0, 100]);
  const [pointsRangeSlider, setPointsRangeSlider] = useState([0, 100]);

  const [nameFilter, setNameFilter] = useState('');
  const [sortBy, setSortBy] = useState('Popularity');


  const [brands, setBrands] = useState(['Hilton', 'Hyatt', 'IHG', 'Marriott']);
  const [subBrands, setSubBrands] = useState({});
  const [brandsLoaded, setBrandsLoaded] = useState(4);

  const [selectedDates, setSelectedDates] = useState([startDate, endDate]);


  const [isLoading, setIsLoading] = useState({
    'Hilton': false,
    'Hyatt': false,
    'IHG': false,
    'Marriott': false
  });

  const [isError, setIsError] = useState({
    'Hilton': false,
    'Hyatt': false,
    'IHG': false,
    'Marriott': false
  });

  const filterRenders = useRef(0);
  const subBrandRenders = useRef(0);
  const initialRenders = 2;

  const errorToast = useToast();

  const [controller, setController] = useState();
  const signal = useRef();


  const [viewMode, setViewMode] = useState(useBreakpointValue({ base: 'card', md: 'both', lg: 'both', xl: 'both', '2xl': 'both' }, { ssr: false })); // 'card' or 'map' or 'both'
  const showButton = useBreakpointValue({ base: true, md: false });

  const toggleViewMode = () => {
    setViewMode(viewMode === 'card' ? 'map' : 'card');
  };

  useEffect(() => {
    console.log(center, bounds, centeredLocation)
    handleSearch(startDate, endDate, { label: 'Cancun, Quintana Roo, Mexico', formatted_address: 'Cancun, Quintana Roo, Mexico' });

  }, []);

  const fetchHotels = async (checkinDate, checkoutDate, searchedLocation) => {
    try {
      if (controller) {
        console.log('aborting...')
        controller.abort();
      }
      const newController = new AbortController();
      setController(newController);
      signal.current = newController.signal;

      console.log('fetching...');
      console.log(`
        location: ${searchedLocation.formatted_address.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}
        checkin: ${checkinDate}
        checkout: ${checkoutDate}
      `)
      const formattedLocation = encodeURIComponent(searchedLocation.formatted_address.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
      const formattedBounds = encodeURIComponent(bounds);
      const formattedCenter = encodeURIComponent(`${center.lat},${center.lng}`);
      const fetchPromises = brandOptions.map(async (brand) => {
        const url = `https://maxmypoint.com/search?cpi=${formattedLocation}&ci=${checkinDate}&co=${checkoutDate}&b=${formattedBounds}&na=2&nr=1&c=${formattedCenter}&brand=${brand}`;
        return fetchHotel(url, brand);
      });

      await Promise.allSettled(fetchPromises);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.log('Error fetching hotels:', error);
      }
    }
  }

  const fetchHotel = async (url, brand) => {
    try {
      for (let i = 0; i < 3; i++) {
        const response = await fetch(url, { signal: signal.current });
        if (response.ok) {
          const data = await response.json();
          if (data.r.error || !data.r || data.r == 'undefined') {
            setIsError(prevError => ({ ...prevError, [brand]: true }));
            errorToast({
              title: `Error searching for ${brand} hotels`,
              description: 'Please try again',
              variant: 'subtle',
              status: 'error',
              duration: 9000,
              isClosable: true
            });
          } else {
            console.log('updating all hotels')
            // add value to data.r
            data.r.forEach(hotel => {
              if (hotel.pr && hotel.pt) {
                hotel.v = hotel.pr * 100 / hotel.pt;
              }
              else hotel.v = null;
            });
            setAllHotels(prevHotels => [...prevHotels, ...data.r]);
          }
          setIsLoading(prevLoading => ({ ...prevLoading, [brand]: false }));
          setBrandsLoaded(prevBrandsLoaded => prevBrandsLoaded + 1);
          setBrandCount(prevBrandCount => ({ ...prevBrandCount, [brand]: data.r.length }));
          return data.r;
        }
      }
      setIsError(prevError => ({ ...prevError, [brand]: true }));
      errorToast({
        title: `Searching ${brand} hotels has timed out`,
        description: 'Please try again',
        variant: 'subtle',
        status: 'error',
        duration: 9000,
        isClosable: true
      });
      throw new Error('Error fetching hotels');
    } catch (err) {
      if (err.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.log('Error fetching hotels:', err);
      }
    }
  }


  // Generate sub brand options for filters based on fetched hotel data and brand filters
  const generateSubBrand = () => {
    console.log('Generating sub brands...');
    const dataSubBrands = {};
    const dataSubBrandsCount = {};
    for (let brand of brands) {
      dataSubBrands[brand] = [];
      dataSubBrandsCount[brand] = [];
    }

    for (let hotel of allHotels) {
      if (dataSubBrands[hotel.b]) {
        if (!dataSubBrands[hotel.b].includes(hotel.sb)) {
          dataSubBrands[hotel.b].push(hotel.sb);
          dataSubBrandsCount[hotel.b][hotel.sb] = 1;
        } else {
          dataSubBrandsCount[hotel.b][hotel.sb] += 1;
        }
      }
    }
    const sortedDataSubBrands = Object.keys(dataSubBrands)
      .sort()
      .reduce((obj, key) => {
        obj[key] = dataSubBrands[key];
        return obj;
      }, {});
    setSubBrandOptions(sortedDataSubBrands);
    setSubBrandCount(dataSubBrandsCount);
    setSubBrands(sortedDataSubBrands);
  }

  // Generate max price and points for range sliders based on fetched hotel data
  const generateMaxFilters = () => {
    console.log('Generating max filters...')
    let newMaxPrice = 0;
    let newMaxPoints = 0;
    for (let hotel of allHotels) {
      if (hotel.pr && hotel.pr > newMaxPrice) {
        newMaxPrice = hotel.pr;
      }
      if (hotel.pt && hotel.pt > newMaxPoints) {
        newMaxPoints = hotel.pt;
      }
    }
    setMaxPrice(newMaxPrice);
    setMaxPoints(newMaxPoints);
    setPriceRange([0, newMaxPrice]);
    setPointsRange([0, newMaxPoints]);
  }

  const filterHotels = () => {
    if (allHotels.length === 0 || subBrandOptions.length === 0) {
      setFilteredHotels([]);
      return;
    }
    console.log('filtering hotels...');
    console.log('price range:', priceRange, 'points range:', pointsRange, 'sub brands:', subBrands, 'name filter:', nameFilter);
    const newFilteredHotels = allHotels.filter(hotel => {
      const isPriceInRange = !hotel.pr || (hotel.pr >= priceRange[0] && hotel.pr <= priceRange[1]);
      const isPointsInRange = !hotel.pt || (hotel.pt >= pointsRange[0] && hotel.pt <= pointsRange[1]);
      const isSubBrandIncluded = subBrands && subBrands[hotel.b] && subBrands[hotel.b].includes(hotel.sb);
      const isNameIncluded = !nameFilter || hotel.n.toLowerCase().includes(nameFilter.toLowerCase().trim());
      //console.log('hotel:', hotel.n, 'price:', isPriceInRange, 'points:', isPointsInRange, 'sub brand:', isSubBrandIncluded)
      return isPriceInRange && isPointsInRange && isSubBrandIncluded && isNameIncluded;
    });

    const newSortedHotels = newFilteredHotels.sort((a, b) => {
      // Define a helper to handle nulls; if a is null, it should come after b
      const handleNulls = (a, b) => {
        if (a === null) return 1; // a is null, b is not, a should come after b
        if (b === null) return -1; // b is null, a is not, a should come before b
        return 0; // neither is null, proceed to normal comparison
      };

      if (sortBy === 'Popularity') {
        const nullComparison = handleNulls(a.qc, b.qc);
        if (nullComparison !== 0) return nullComparison;
        return b.qc - a.qc;
      } else if (sortBy === 'Lowest Points') {
        const nullComparison = handleNulls(a.pt, b.pt);
        if (nullComparison !== 0) return nullComparison;
        return a.pt - b.pt;
      } else if (sortBy === 'Lowest Price') {
        const nullComparison = handleNulls(a.pr, b.pr);
        if (nullComparison !== 0) return nullComparison;
        return a.pr - b.pr;
      } else if (sortBy === 'Highest Value') {
        const nullComparison = handleNulls(a.v, b.v);
        if (nullComparison !== 0) return nullComparison;
        return b.v - a.v;
      } else if (sortBy === 'Highest Price') {
        const nullComparison = handleNulls(a.pr, b.pr);
        if (nullComparison !== 0) return nullComparison;
        return b.pr - a.pr;
      }
    });

    console.log('sorted hotels:', newSortedHotels);

    setFilteredHotels(newFilteredHotels);
    const newHotelCoords = [];
    for (let hotel of newFilteredHotels) {
      newHotelCoords.push({
        name: hotel.n,
        lat: hotel.g[0],
        lng: hotel.g[1],
        key: hotel.id,
        points: hotel.pt,
        price: hotel.pr,
        i: hotel.i,
        v: hotel.v
      });
    }
    setHotelCoords(newHotelCoords);
  }

  // Fetch hotel data when searched
  const handleSearch = (checkinDate, checkoutDate, searchedLocation) => {

    checkinDate = moment(checkinDate).format('YYYY-MM-DD')
    checkoutDate = moment(checkoutDate).format('YYYY-MM-DD')
    console.log(`handle search called: ${checkinDate}, ${checkoutDate}, ${JSON.stringify(searchedLocation)}`)
    console.log(`center: ${JSON.stringify(center)}`, `bounds: ${bounds}`)

    let errorMessages = [];
    if (!searchedLocation) {
      errorMessages.push('location with the autocomplete dropdown');
    }
    if (!checkinDate || Date.parse(checkinDate) < Date.now()) {
      errorMessages.push('check in date');
    }
    if (!checkoutDate || Date.parse(checkoutDate) < Date.now() || Date.parse(checkoutDate) <= Date.parse(checkinDate)) {
      errorMessages.push('check out date');
    }
    if (errorMessages.length > 0) {
      errorToast({
        title: 'Error searching for hotels',
        description: 'Please enter a valid ' + errorMessages.join(', '),
        variant: 'subtle',
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top'
      });
    }
    else {
      // Set loading states
      // searchedLocation = { formatted_address: searchedLocation.label }
      setAllHotels([]);
      setBrandCount({
        'Hilton': 0,
        'Hyatt': 0,
        'IHG': 0,
        'Marriott': 0
      });
      setIsLoading({
        'Hilton': true,
        'Hyatt': true,
        'IHG': true,
        'Marriott': true
      });
      setIsError({
        'Hilton': false,
        'Hyatt': false,
        'IHG': false,
        'Marriott': false
      });
      setBrandsLoaded(0);

      // Reset filters
      setPriceRange([0, maxPrice]);
      setPointsRange([0, maxPoints]);
      setPriceRangeSlider([0, 100]);
      setPointsRangeSlider([0, 100]);
      setBrands(['Hilton', 'Hyatt', 'IHG', 'Marriott']);
      setSubBrands({});
      setNameFilter('');

      console.log(`setting centered location: ${JSON.stringify(center)}`)
      setCenteredLocation(center);
      fetchHotels(checkinDate, checkoutDate, searchedLocation);
    }
  }


  const getHotelData = (hotelId) => {
    //console.log('gethoteldata', hotelId, allHotels)
    return allHotels.find(hotel => hotel.id == hotelId);
  }

  useEffect(() => {
    if (subBrandRenders.current < initialRenders) {
      subBrandRenders.current++;
      return;
    }
    generateSubBrand();
    generateMaxFilters();
  }, [brands, allHotels]);

  useEffect(() => {
    if (filterRenders.current < initialRenders) {
      filterRenders.current++;
      return;
    }
    filterHotels();
  }, [priceRange, pointsRange, subBrands, nameFilter, sortBy]);

  return (
    <Flex direction='row' height='100%'>
      <Flex direction='column' px={6} width={['100%', 'container.md']} height='calc(100vh - 50px)' gap={1}>
        <MapSearchBar
          handleSearch={handleSearch}
          setBounds={setBounds}
          setCenter={setCenter}
          selectedDates={selectedDates}
          setSelectedDates={setSelectedDates}
        />
        <Progress value={100 * (brandsLoaded / 4)} hasStripe size='md'/>
        {/* <Divider borderColor='gray.500' mb={2} /> */}
        <SearchFilterBar
          resultCount={filteredHotels.length}
          brands={brands}
          brandCount={brandCount}
          setBrands={setBrands}
          subBrandOptions={subBrandOptions}
          subBrandCount={subBrandCount}
          subBrands={subBrands}
          setSubBrands={setSubBrands}
          maxPrice={maxPrice}
          setPriceRange={setPriceRange}
          maxPoints={maxPoints}
          setPointsRange={setPointsRange}
          priceRangeSlider={priceRangeSlider}
          setPriceRangeSlider={setPriceRangeSlider}
          pointsRangeSlider={pointsRangeSlider}
          setPointsRangeSlider={setPointsRangeSlider}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          sortBy={sortBy}
          setSortBy={setSortBy}
          brandsLoaded={brandsLoaded}
        />
      {showButton && (
        <Button
          leftIcon={<ViewIcon />}
          position="fixed"
          bottom="20px"
          left="50%"
          transform="translateX(-50%)"
          zIndex="overlay"
          colorScheme="teal"
          aria-label="Toggle View"
          onClick={toggleViewMode}
        >
          {viewMode === 'card' ? 'View Map' : 'View List'}
        </Button>
      )}
        {(viewMode === 'card' || viewMode === 'both') && (
        <Stack overflowY='auto' mt={3} flexGrow='1' align='center' width='100%'>
          <Stack direction='column' gap={3} justify='center' width='100%'>
            {brandsLoaded != 0 && filteredHotels.map((hotel) => (
              <SearchHotelCard
                width={{ base: "100%", md: "100%" }}
                id={hotel.id}
                key={hotel.id}
                hotel={hotel}
              />
            ))}
          </Stack>
          {brandsLoaded == 0 && (
            <>
              <Spinner size='xl' color='blue.500' mt={4} />
              <Text>Loading hotels...</Text>
            </>
          )}
        </Stack>
        )}
      {(viewMode === 'map') && (
        <Map
          centeredLocation={centeredLocation}
          hotelCoords={hotelCoords}
          // getHotelData={getHotelData}
        />
      )}
        <Divider borderColor='gray.500' mt={2} />
        <Stack direction='row' mb={3} px={3} py={1}>
          <Text whiteSpace='nowrap'>Search progress:</Text>
          <Flex direction='row' justify='space-around' width='100%'>
            {Object.keys(isLoading).map((brand) => (
              <Stack key={brand} direction='row' gap={2} align='center'>
                <Text>{brand}</Text>
                {isError[brand] && <Icon as={FaXmark} color='red.500' />}
                {!isError[brand] && isLoading[brand] && <Spinner color='blue.500' />}
                {!isError[brand] && !isLoading[brand] && <Icon as={FaCheck} color='blue.500' />}
              </Stack>
            ))}
          </Flex>
        </Stack>
      </Flex>
      {(viewMode === 'both') && (
        <Map
          centeredLocation={centeredLocation}
          hotelCoords={hotelCoords}
          // getHotelData={getHotelData}
        />
      )}
    </Flex>
  );
};

export default MapSearchPage;
