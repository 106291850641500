import { Button, Box, Menu, MenuButton, MenuList, Stack, Text } from '@chakra-ui/react';
import React from 'react';
import { HiMiniChevronUp, HiMiniChevronDown } from 'react-icons/hi2';

const SearchMenuButton = (props) => {
  // console.log(`SearchMenuButton props: ${props}`, props);

  return (
    <Menu closeOnSelect={false}>
      {({ isOpen }) => (
        <>
          <MenuButton isActive={isOpen} as={Button} size='sm' colorScheme='blue' rightIcon={isOpen ? <HiMiniChevronUp /> : <HiMiniChevronDown />} minW='120px' width='auto'>
            <Stack gap={1} direction='row' align='center'>
              {props.icon}
              <Text>{props.title}</Text>
            </Stack>
          </MenuButton>
          <MenuList maxH='md' overflow='scroll' pb={0}>
            {props.children}
            <Box
              position='sticky'
              bottom={0}
              bg='white'
              borderTop='1px solid'
              borderColor='gray.200'
              width='100%'
              mt={2}
            >
              <Button 
                colorScheme='blue' 
                size='sm' 
                variant='ghost' 
                width='100%'
                onClick={props.reset}
              >
                Reset
              </Button>
            </Box>
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default SearchMenuButton;
