import React, { useState, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { bounds } from 'leaflet';
import { Box, Card, CardBody, Flex, Heading, Image, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import { Link as ChakraLink } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';


const defaultCenter = { lat: 21.161908, lng: -86.8515279 };

// Fix the default icon issue in Leaflet 1.x
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


const Map = ({ centeredLocation, hotelCoords }) => {
  const [shouldUpdateCenter, setShouldUpdateCenter] = useState(true);
  const [selectedHotel, setSelectedHotel] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const cppLabel = useBreakpointValue({ base: "CPP", md: "Cents Per Point" });

  const handleMarkerClick = (hotel) => {
    setSelectedHotel(hotel);
    setImageUrl(hotel.i);
    setShouldUpdateCenter(false); // Explicitly allow center update after marker click
  };

  const ChangeView = ({ center }) => {
    console.log(`chaging center: ${JSON.stringify(center)}, shouldUpdate: ${shouldUpdateCenter}`);
    // setSelectedHotel(null);
    const map = useMap();
    useEffect(() => {
      if (shouldUpdateCenter) {
        setSelectedHotel(null);
        map.flyTo(center, 10);
        const bounds = map.getBounds(); // Get the current map bounds
        console.log('bounds:', bounds); // Log or use the bounds as needed
          // Optionally reset shouldUpdateCenter here or elsewhere as needed
      }
    }, [centeredLocation, center, shouldUpdateCenter, map]);
  
    return null;
  };
  
  useEffect(() => {
    // Whenever centeredLocation changes, allow the map center to be updated
    console.log(`centeredLocation: ${JSON.stringify(centeredLocation)}`);
    console.log(shouldUpdateCenter);
    setShouldUpdateCenter(true);
  }, [centeredLocation]);


  // console.log(`map centeredLocation: ${JSON.stringify(centeredLocation)}`)
  return (
    <Box flexGrow='1' align='stretch' height="100vh">
      <MapContainer
        style={{ height: '100%', width: '100%' }}
        center={centeredLocation && Object.keys(centeredLocation).length !== 0 ? centeredLocation : defaultCenter}
        zoom={10}
        scrollWheelZoom={true}
      >
        <ChangeView center={centeredLocation && Object.keys(centeredLocation).length !== 0 ? centeredLocation : defaultCenter} /> Step 3: Use ChangeView to update the center
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {hotelCoords.map((hotel, index) => {
          // console.log(`hotel: ${JSON.stringify(hotel)}`);
          const customIcon = new L.DivIcon({
            html: `
              <div style="position: relative; background-color: var(--chakra-colors-blue-500); padding: 5px; border-radius: 5px; text-align: center; color: white; font-weight: bold;">
              ${hotel.points ? (hotel.points / 1000).toFixed(1).toLocaleString() + 'K' : hotel.price ? '$' + Math.round(hotel.price).toLocaleString() : 'N/A'}
                <div style="position: absolute; left: 50%; bottom: -10px; width: 0; height: 0; border-left: 10px solid transparent; border-right: 10px solid transparent; border-top: 10px solid var(--chakra-colors-blue-500); transform: translateX(-50%);"></div>
              </div>
            `, // Customize this style as needed
            className: 'custom-div-icon', // Custom class for additional styling
            iconSize: [60, 20], // Adjust size as needed
          });
          return (<Marker
            key={index}
            position={[hotel.lat, hotel.lng]}
            icon={customIcon}
            eventHandlers={{
              click: () => handleMarkerClick(hotel),
              // mouseover: () => {
              //   if (selectedHotel?.key !== hotel.key) {
              //     setSelectedHotel(hotel);
              //     setImageUrl(hotel.i);
              //     // getHotelImage(); // Uncomment if needed
              //   }              
              // },
              // mouseout: { onMouseOutMarker }

            }}
          />)
        })}
        {selectedHotel && (
          <Popup
            position={[selectedHotel.lat, selectedHotel.lng]}
            onClose={() => {
              setSelectedHotel(null);
            }}
          >
            <Card
              overflow='hidden'
              align='center'
              h='100%'
            >
              <Flex direction={{ base: 'column', md: 'column' }} borderBottom={{ base: '1px', md: 'none' }} borderColor='gray.300' width='100%' align='center'>
                <Image src={imageUrl} alt={selectedHotel.name} width='100%' 
                  maxW={{ base: '100%', md: '100%' }} maxH={{ base: '150px', md: '250px' }}
                  objectFit='cover' align="center"/>
                <CardBody p={{ base: 3, md: 5 }} minWidth='100px'>
                  <Flex gap={{ base: 1, md: 3 }} align='center' direction='column' justify={{ base: 'center' }} height='100%'>
                    <Heading
                      size='md'
                      textAlign='center'
                      color='blue.500'
                    >
                      <ChakraLink as={RouterLink} to={`https://maxmypoint.com/hotel/${selectedHotel.key}`} target='_blank'>
                        {selectedHotel.name}
                      </ChakraLink>                    
                    </Heading>
                    <Flex direction='row' justify='space-around' width='100%' gap={3}>
                      <Stack direction='row'>
                        <Text>Total Price:</Text>
                        <Text color='blue.500' fontWeight='bold'>{selectedHotel.price ? '$' + selectedHotel.price : 'N/A'}</Text>
                      </Stack>
                      <Stack direction='row'>
                        <Text>Total Points</Text>
                        <Text color='blue.500' fontWeight='bold'>{selectedHotel.points ? selectedHotel.points / 1000 + 'K' : 'N/A'}</Text>
                      </Stack>
                      <Stack direction='row'>
                        <Text>{cppLabel}</Text>
                        <Text color='blue.500' fontWeight='bold'>{selectedHotel.v ? (selectedHotel.v).toFixed(1).toLocaleString() : 'N/A'}</Text>
                      </Stack>
                    </Flex>
                  </Flex>
                </CardBody>
              </Flex>
            </Card>
          </Popup>
        )}
      </MapContainer>
    </Box>
  );
};

export default Map;